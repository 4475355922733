import { useCallback, useEffect } from 'react'
import { getPreciseDistance } from 'geolib'
import { positonStore } from '@/store'

export function useWatchCurrentPosition({ currentPosition = {
  latitude: undefined,
  longitude: undefined,
} } = { }) {
  const position = positonStore(state => state?.position)
  const setPosition = positonStore(state => state?.setPosition)

  console.log('currentPosition', currentPosition)

  const updateCurrentPosition = useCallback(({ latitude, longitude } = {}) => {
    console.log('useWatchCurrentPosition')
    // console.log(JSON.stringify({
    //   latitude,
    //   longitude,
    // }))
    // console.log(JSON.stringify({
    //   latitude: position.lat,
    //   longitude: position.lng,
    // }))
    const distance = getPreciseDistance({
      latitude,
      longitude,
    }, {
      latitude: position.lat,
      longitude: position.lng,
    }, 1)

    console.log(`当前位置与上次定位位置相差 ${distance} m`)

    if (distance < 1)
      return

    setPosition({
      lat: latitude,
      lng: longitude,
    })
  }, [setPosition, position])

  useEffect(() => {
    if (window.vuplex) {
      if (currentPosition.latitude === position.lat && currentPosition.longitude === position.lng)
        return () => {}
      if (!currentPosition.longitude)
        return () => {}

      updateCurrentPosition(currentPosition)
      return () => {}
    }
    //
    // const watchId = navigator.geolocation.watchPosition(({ coords }) => {
    //   updateCurrentPosition(coords)
    // }, () => void 0, {
    //   enableHighAccuracy: true,
    //   timeout: 5000,
    //   maximumAge: 0,
    // })
    //
    // return () => {
    //   navigator.geolocation.clearWatch(watchId)
    // }
  }, [currentPosition, position])

  return {
    updateCurrentPosition,
  }
}

import { Suspense } from 'react'
import { RouterProvider } from 'react-router-dom'
import { ConfigProvider } from 'react-vant'
import JP from 'react-vant/es/locale/lang/ja-JP'
import { SWRConfig } from 'swr'
import { Preloader } from '@/components'
import FullScreenLoading from '@/components/Loading'
import { useConnectApp } from '@/hooks'
import router from '@/router'
import { fullScreenLoadingStore } from '@/store'
import './App.scss'

const themeVars = {
  'buttonPrimaryBackgroundColor': '#191919',
  'buttonBorderRadius': '10px',
  'button-large-height': '58px',
}

function App() {
  const fullScreenLoadingVisible = fullScreenLoadingStore(state => state?.visible)
  console.log('fullScreenLoadingVisible', fullScreenLoadingVisible)
  useConnectApp()

  return (
    <Suspense fallback={<Preloader />}>
      <FullScreenLoading visible={fullScreenLoadingVisible} />
      {/* <GetLocation></GetLocation> */}
      <SWRConfig
        value={{
          dedupingInterval: 2000,
          revalidateOnFocus: false,
        }}
      >
        <ConfigProvider className="vant-config-provider" themeVars={themeVars} locale={JP}>
          <RouterProvider router={router}>
          </RouterProvider>
        </ConfigProvider>
      </SWRConfig>
    </Suspense>
  )
}

export default App

import { useMatches, useNavigate } from 'react-router-dom'
import { useMemo,useEffect, useState  } from 'react'
import { ConfigProvider, NavBar } from 'react-vant'
import { ArrowLeft } from '@react-vant/icons'

function NavTitle() {
  const navigate = useNavigate()
  const matches = useMatches() // 这个钩子只有 data Router 下能用
  const { back = true, title = '', noNav, navBorder = true } = useMemo(() => {
    return matches?.slice?.(-1)?.[0]?.handle || {}
  }, [matches]);

  const [titleFontSize, setTitleFontSize] = useState('18px'); // 默认标题大小

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768 && window.innerWidth <= 1366) {
        setTitleFontSize('34px'); // 平板设备的标题大小
      } else {
        setTitleFontSize('18px'); // 默认标题大小
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // 初始化时调用一次
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function navBack(){
    // 因为第三方登录由新窗口改为本页面跳转后 (1: 当前插件使用新窗口的方式, 第一次打开时authCode消息可能传递不到父窗口; 2: UniWebview默认配置没有开启支持多窗口; 3: 父窗口监听storage事件发生时, 获取到的authCode值可能不是最新值 (dirty fix: 延迟一段时间后读取)), 第三方登录成功/失败返回本系统后, 再返回上一页会跳到第三方页面
    if(location.href.indexOf('account/login')>=0){
      navigate('/home')
    }else{
      navigate(-1)
    }
  }

  if (noNav)
    return (<></>)
  return (
    <ConfigProvider themeVars={{
      '--rv-nav-bar-icon-color': '#0B1526',
      '--rv-nav-bar-title-font-size': titleFontSize,
      '--rv-font-weight-bold': '700',
    }}
    >
      <NavBar
        title={title || ''}
        leftText=""
        border={navBorder}
        leftArrow={back ? <ArrowLeft /> : false}
        // fixed={true}
        // placeholder={true}
        // safeAreaInsetTop={true}
      // rightText="按钮"
        onClickLeft={() => navBack()}
      />
    </ConfigProvider>
  )
}

export default NavTitle

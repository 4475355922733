import React from 'react'
import { NavLink } from 'react-router-dom'
import './index.scss'
import { useConnectApp } from '@/hooks'

const tabList = [
  {
    url: '/home',
    icon: 'map',
    title: 'マップ',
  },
  {
    url: '/news',
    icon: 'list',
    title: '最新情報',
  },
  {
    url: '/AR/home',
    icon: 'list',
    title: '',
  },
  {
    url: '/notification',
    icon: 'chat',
    title: 'お知らせ',
  },
  {
    url: '/my',
    icon: 'my',
    title: 'マイページ',
  },
]

function TabBar(i) {
  return (
    <NavLink
      key={i.url}
      to={i.url}
      className={({ isActive }) => `${isActive ? 'active' : ''} tabBarBox`}
    >
      {({ isActive }) => (
        <div>
          <img
            className="tabIcon"
            src={`/assets/img/tabbar/${i.icon}_${isActive ? 'y' : 'n'}.svg`}
            alt="map"
          />
          {isActive}
          <div>{i.title}</div>
        </div>
      )}
    </NavLink>
  )
}

function TabBarCenterButton(i) {
  const { send } = useConnectApp()

  return (
    <div
      key={i.url}
    //   to={i.url}
      onClick={() => send({
        type: 1, // 1.tab中间按钮  2.点击活动  3.点击收集AR
        // projectId: '', // 活动ID
        // arId: 'arID', // arID
      })}
      className="tabCenterButton"
    >
      <img
        className="tabIcon"
        src="/assets/img/tabbar/tab_button.svg"
        alt="map"
      />
    </div>
  )
}

function Footer() {
  return (
    <>
      <footer className="tabRow">
        {tabList.map(i => i.title ? TabBar(i) : TabBarCenterButton(i))}
      </footer>
    </>
  )
}

export default Footer

import {ConfigProvider, Image, Search} from 'react-vant'
import PropType from 'prop-types'
import searchIcon from '@/assets/img/icons/search.svg'
import './index.scss'

function CommonSearch({
                        value,
                        onChange,
                        style,
                        placeholder = 'キーワードを入力してください…',
                        className,
                        actionText,
                        showAction = false
                      }) {
  return (
    <ConfigProvider
      className={`${className} common-search`}
      style={style}
      themeVars={
        {
          '--rv-search-content-background-color': '#fff',
          '--rv-search-padding': '0',
        }
      }
    >
      <Search
        className="common-search-instance"
        value={value}
        onChange={onChange}
        clearable
        showAction={showAction}
        placeholder={placeholder}
        actionText={actionText}
        leftIcon={<Image className='common-search-instance-icon' src={searchIcon}/>}
      />

    </ConfigProvider>
  )
}

CommonSearch.propTypes = {
  value: PropType.string,
  onChange: PropType.func,
  style: PropType.object,
  placeholder: PropType.string,
  className: PropType.string,
}

export default CommonSearch

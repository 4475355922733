import { useCallback, useRef } from 'react'
import { useEvent } from 'react-use'
import { Dialog } from 'react-vant'
import { isEqual } from 'lodash-es'
import { useWatchCurrentPosition } from './'
import { useStore } from '@/store'
import router from '@/router'

export function useConnectApp({ thirdPartyLogin } = {}) {
  const useInfo = useStore(state => state?.userInfo)
  const setUserInfo = useStore.getState()?.setUserInfo
  // const position = positonStore(state => state?.position)

  // const [currentPosition, setCurrentPosition] = useState({
  //   latitude: undefined,
  //   longitude: undefined,
  // })

  const currentPositionRef = useRef({
    latitude: undefined,
    longitude: undefined,
  })

  // const throttledGeoInfo = useThrottle(currentPosition, 1000)

  // console.log('throttledGeoInfo', throttledGeoInfo)

  // const throttledGeoInfo = useThrottleFn((geoInfo) => {
  //   // if (!geoInfo.latitude || !geoInfo.longitude)
  //   //   return position

  //   // if (position?.lat !== geoInfo?.latitude || position?.lng !== geoInfo?.longitude) {
  //   //   return {
  //   //     lat: geoInfo.latitude || 0,
  //   //     lng: geoInfo.longitude || 0,
  //   //   }
  //   // }

  //   // return geoInfo

  //   console.log('throttledGeoInfo', geoInfo)

  //   return {
  //     lat: geoInfo.latitude,
  //     lng: geoInfo.longitude,
  //   }
  // }, 1000, [currentPosition])

  const setCurrentPositionFromMessage = ({ latitude, longitude }) => {
    if (!isEqual({
      latitude,
      longitude,
    }, currentPositionRef.current)) {
      currentPositionRef.current = {
        latitude,
        longitude,
      }
      console.log('currentPositionRef', currentPositionRef.current)
    }
  }

  useWatchCurrentPosition({ currentPosition: currentPositionRef.current })

  const back = useCallback(() => router.navigate(-1), [router])

  const onMessage = useCallback((event) => {
    const { type, message, data } = JSON.parse(event.data.replace(/\\n/g, '\n'))

    // console.log(type, message, data)
    if (type === 'login') {
      router.navigate('/account/login', {
        replace: true,
      })
    }
    if (type === 'userInfoInUnity') {
      // alert(`${type}: ${message}`)
      window.userInfoInUnity = JSON.parse(message);
      // alert(window.userInfoInUnity);
    }
    if (type === 'showMap') {
      router.navigate('/home')
    }
    else if (type === 'location') {
      // console.log('location message', message)
      setCurrentPositionFromMessage(message)
    }
    else if (type === 'Escape') {
      back()
    }
    else if (type === 'google') {
      thirdPartyLogin?.({
        thirdType: 1,
        idToken: data.tokenResponse.id_token,
        redirectUri: window.location.href,
      })
    }
    else if (type === 'facebook') {
      thirdPartyLogin?.({
        thirdType: 3,
        idToken: data.tokenResponse.id_token,
        redirectUri: window.location.href,
      })
    }
    else {
      console.log(message, type)
    }
  }, [setCurrentPositionFromMessage, back])

  /**
   * 发送消息给 unity
   * @type    1.tab中间按钮识别图  2.点击活动 gps  3.收集AR 平面图 4 谷歌  5 facebook 6、 预留 7、 预留 8、打开外部URL
   */
  const send = useCallback((msg, isLogining = false) => {
    if (!useInfo?.token && !isLogining && !window.userInfoInUnity) {
      setUserInfo(window.userInfoInUnity);
    }

    if (!useInfo?.token && !isLogining) {
      Dialog.confirm({
        title: 'お知らせ',
        message: 'ログインしてください',
        confirmButtonText: '登録',
      })

      router.navigate('/account/login', {
        replace: true,
      })

      return
    }
    console.log({ ...msg, token: useInfo.token, uid: useInfo.uid ?? 0 })
    window.vuplex.postMessage({ ...msg, token: useInfo.token, uid: useInfo.uid ?? 0 })
  }, [useInfo])

  if (window.vuplex)
    useEvent('message', onMessage, window.vuplex)

  // useEffect(() => {
  //   // console.log('effect throttledGeoInfo', throttledGeoInfo)
  //   // console.log('effect position', position)

  //   if (!throttledGeoInfo)
  //     return

  //   setPosition(throttledGeoInfo)
  // }, [throttledGeoInfo])

  return {
    send,
  }
}

import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

export const DEFAULT_POSITION = {
  lat: 35.6586937,
  lng: 139.7454543,
}

export const useStore = create(
  persist(
    set => ({
      userInfo: undefined,
      rememberPassword: false,
      rememberPasswordParams: undefined,
      setUserInfo: (userInfo) => {
        set({
          userInfo,
        })
      },

      setRememberPassword: (rememberPassword) => {
        set({
          rememberPassword,
        })
      },

      setRememberPasswordParams: (rememberPasswordParams) => {
        set({
          rememberPasswordParams,
        })
      },
    }),
    {
      name: 'stote',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export const positonStore = create(

  set => ({
    position: DEFAULT_POSITION,

    setPosition: (position) => {
      set({
        position,
      })
    },
  }),
)

export const fullScreenLoadingStore = create(
  set => ({
    visible: false,
    config: {
      overlay: {},
      loading: {},
    },
    show: ({
      overlay,
      loading,
    } = {
      overlay: {},
      loading: {},
    }) => {
      set({
        visible: true,
        config: {
          overlay,
          loading,
        },
      })
      console.trace(`show loading`)
    },

    hide() {
      set({
        visible: false,
      })
      console.trace(`hide loading`)
    },
  }),
)

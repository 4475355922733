import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import Footer from '@/components/Footer'
import './index.scss'

function DefaultLayout() {
  const { pathname } = useLocation()

  const navigate = useNavigate()

  useEffect(() => {
    if (pathname === '/') {
      navigate('/home', {
        replace: true,
      })
    }
  }, [])

  return (
    <>
      <div className="default-layout">
        <Outlet />
      </div>
      <Footer />
    </>
  )
}

export default DefaultLayout

import {lazy} from 'react'
import {createBrowserRouter,} from 'react-router-dom'

import {AuthLayout, TabbarLayout,} from '@/layouts'

const Welcome = lazy(() => import('@/pages/Welcome'))

const Account = lazy(() => import('@/pages/Account'))
const Login = lazy(() => import('@/pages/Account/Login'))
const Profile = lazy(() => import('@/pages/Account/Profile/Profile'))
const Register = lazy(() => import('@/pages/Account/Register'))
const BindEmail = lazy(() => import('@/pages/Account/BindEmail'))
const VerifyEmail = lazy(() => import('@/pages/Account/VerifyEmail'))
const RestPassword = lazy(() => import('@/pages/Account/RestPassword'))
const ResetPasswordConfirm = lazy(() => import('@/pages/Account/RestPassword/ResetPasswordConfirm'))

const Home = lazy(() => import('@/pages/Home'))

const NewsList = lazy(() => import('@/pages/News/List'))
const NewsDetail = lazy(() => import('@/pages/News/Detail'))

const My = lazy(() => import('@/pages/My/My'))
const Notification = lazy(() => import('@/pages/Notification'))
const NotFound = lazy(() => import('@/pages/NotFound'))
const AR = lazy(() => import('@/pages/AR/AR'))
const RuleConditions = lazy(() => import('@/pages/My/RuleConditions/RuleConditions'))
const Activities = lazy(() => import('@/pages/My/Activities/Activities'))
const GiftList = lazy(() => import('@/pages/My/GiftList/GiftList'))
const ArAtom = lazy(() => import('@/pages/My/ArAtom/index'))

const DetailPage = lazy(() => import('@/pages/Welcome/DetailPage'))
const HomeList = lazy(() => import('@/pages/Home/HomeList'))

function ErrorBoundary() {
  return <></>
}

const router = createBrowserRouter([
    {
      path: '/welcome',
      Component: Welcome,

    },

    {
      path: '/detail',
      Component: DetailPage,
    },

    {
      path: '/account',
      Component: Account,
      children: [
        {
          path: 'profile',
          Component: Profile,
          handle: {
            title: '設定',

          },
        },
        {
          path: 'login',
          Component: Login,
          handle: {
            title: '',
            navBorder: false,
          },
        },
        {
          path: 'register',
          Component: Register,
          handle: {
            title: '',
            navBorder: false,
          },
        },
        {
          path: 'bindEmail',
          Component: BindEmail,
          handle: {
            title: '',
            navBorder: false,
          },
        },
        {
          path: 'verifyEmail',
          Component: VerifyEmail,
          handle: {
            title: '',
            navBorder: false,
          },
        },
        {
          path: 'resetPassword',
          Component: RestPassword,
          handle: {
            title: '',
            navBorder: false,
          },
        },
        {
          path: 'resetPasswordConfirm',
          Component: ResetPasswordConfirm,
          handle: {
            title: '',
            navBorder: false,
          },
        },
      ],
    },

    {
      path: '/',
      Component: AuthLayout,
      errorElement: ErrorBoundary,
      children: [
        {
          path: '/',
          Component: TabbarLayout,
          children: [
            {
              path: 'home',

              children:[
                {
                  path: '',
                  index: true,
                  Component: Home,
                  handle: {
                    title: 'マップ',
                    // noNav: true,
                    back: false,
                  },
                },
                {
                  path: 'list',
                  Component: HomeList,
                  handle: {
                    title: 'イベントリスト',
                    // noNav: true,
                    back: true,
                  },
                }
              ],
            },
            {
              path: 'news',
              Component: NewsList,
              handle: {
                title: '最新情報',
                noNav: true,
                back: false,
              },
            },
            {
              path: 'my',
              Component: My,
              handle: {
                noNav: true,
              },
            },
            {
              path: 'notification',
              handle: {
                title: 'お知らせ',
                back: false,
              },
              Component: Notification,
            },

          ],
        },

        {
          path: '/AR/:ID',
          Component: AR,
        },

        {
          path: '/news/detail/:ID',
          Component: NewsDetail,
          handle: {
            title: 'アクティビティの詳細',
            // back: false,
          },
        },
        {
          path: '/news/detail/arlist',
          Component: NewsDetail,
          handle: {
            title: '平面をスキャン',
            // back: false,
          },
        },
        {
          path: '/my/activities',
          Component: Activities,
          handle: {
            noNav: true,
          },
        },
        {
          path: '/my/giftList',
          Component: GiftList,
          handle: {
            noNav: true,
          },
        },
        {
          path: '/my/RuleConditions',
          Component: RuleConditions,
          handle: {
            title: 'Terms & Condition',
          },
        },
        {
          path: '/my/ar-atom',
          Component: ArAtom,
          handle: {
            noNav: true,
          },
        },

      ],
    },

    {
      path: '*',
      Component: NotFound,
    },
  ],
)

export default router

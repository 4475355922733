import { Outlet } from 'react-router-dom'
import { NavBar } from '@/components'

function DefaultLayout() {
  // const uesrInfo = useStore(state => state.userInfo)
  // const navigate = useNavigate()

  // useEffect(() => {
  //   if (!uesrInfo?.token) {
  //     navigate('/account/login', {
  //       replace: true,
  //     })
  //   }
  // }, [])

  return (
    <>
      <NavBar />
      <Outlet></Outlet>
    </>
  )
}

export default DefaultLayout
